
import { ref, watch, onMounted, onBeforeUnmount, Ref } from "vue";

let list: Array<Ref<number>> = [];

function enable(obj: Ref<number>): void {
    obj.value = Math.max(2000, ...list.map(e => e.value)) + 10;
    list.push(obj);
}

function disable(obj: Ref<number>): void {
    list = list.filter(e => e.value !== obj.value);
}

export function useZIndex(watchable?: Parameters<typeof watch>[0]): Ref<number> {
    const obj = ref<number>(0);
    
    onMounted(() => {
        enable(obj);
    });

    onBeforeUnmount(() => {
        disable(obj);
    });

    if (watchable) {
        watch(watchable, (val) => {
            if (val) {
                enable(obj);
            } else {
                disable(obj);
            }
        });
    }

    return obj;
}
